import { ReactNode, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import { useFormikContext } from 'formik';
import {
  ResponseHandlerProps,
  Tooltip,
  TooltipTrigger,
  useResponseHandler
} from 'src/common';
import { useButtonDisabledDebounce } from 'src/common-v2';
import { FormValidationFailedError, getTitleCase } from 'src/lib';
import { PermissionId } from 'src/types';

export enum FormButtonType {
  BASE,
  DELETE_EXISTING_ENTITY,
  SAVE_CHANGES_TO_EXISTING_ENTITY,
  SAVE_NEW_ENTITY,
  NAVIGATE_BACK,
  RESET,
  DOWNLOAD,
  CANCEL
}

type FormButtonBaseProps = {
  type: FormButtonType;
  label?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  isHidden?: boolean;
  requiredPermissions?: PermissionId[];
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  variant?:
    | 'text'
    | 'delete'
    | 'outlined'
    | 'contained'
    | 'edit'
    | 'back'
    | 'approve'
    | 'bookingsApprove'
    | 'bookingsReject';
  color?:
    | 'inherit'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info';
};

type FormButtonBase = {
  type: FormButtonType.BASE;
  label: string;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonBack = {
  type: FormButtonType.NAVIGATE_BACK;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonDelete = {
  type: FormButtonType.DELETE_EXISTING_ENTITY;
  entityName: string;
  /** callback called when button clicked - if not specified, the form submit will be used instead */
  // eslint-disable-next-line
  serviceCall?: () => Promise<any>;
  successProps?: {
    description: string;
    title: string;
    // eslint-disable-next-line
    onResponseReceivedCallback?: (response: any) => ResponseHandlerProps;
    onSuccessCallback?: () => void;
  };
  onSuccessCallback?: () => void;
  // eslint-disable-next-line
  onResponseReceivedCallback?: (response: any) => ResponseHandlerProps;
} & FormButtonBaseProps;

type FormButtonSaveExisting = {
  type: FormButtonType.SAVE_CHANGES_TO_EXISTING_ENTITY;
  entityName: string;
  onClick?: () => void;
  onSuccessCallback?: () => void;
  getSuccessMessageTitle?: (entityName: string) => string;
  getFailureMessageTitle?: (entityName: string) => string;
  isResponseHandlingDisabled?: boolean;
} & FormButtonBaseProps;

type FormButtonSaveNew = {
  type: FormButtonType.SAVE_NEW_ENTITY;
  entityName: string;
  onClick?: () => void;
  onSuccessCallback?: () => void;
  getSuccessMessageTitle?: (entityName: string) => string;
  getFailureMessageTitle?: (entityName: string) => string;
  isResponseHandlingDisabled?: boolean;
} & FormButtonBaseProps;

type FormButtonDownload = {
  type: FormButtonType.DOWNLOAD;
  entityName: string;
} & FormButtonBaseProps;

type FormButtonReset = {
  type: FormButtonType.RESET;
} & FormButtonBaseProps;

type FormButtonCancel = {
  type: FormButtonType.CANCEL;
  entityName: string;
  onClick?: () => void;
} & FormButtonBaseProps;

export type FormButtonControlConfig =
  | FormButtonBase
  | FormButtonBack
  | FormButtonDelete
  | FormButtonSaveExisting
  | FormButtonSaveNew
  | FormButtonReset
  | FormButtonDownload
  | FormButtonCancel;

type FormButtonsControlProps = {
  buttonConfig: FormButtonControlConfig;
};

export const FormButtonControl = ({
  buttonConfig
}: FormButtonsControlProps) => {
  const { handleServiceCall } = useResponseHandler();
  const navigate = useNavigate();
  const [isDownloadButtonDebouncing, setIsDownloadButtonDebouncing] =
    useState(false);
  const { buttonDisabledDebounce } = useButtonDisabledDebounce();

  const {
    values,
    submitForm: submitFormFormik,
    validateForm,
    setSubmitting
  } = useFormikContext();

  const submitForm = useCallback(() => {
    setSubmitting(true);
    return validateForm(values)
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return submitFormFormik();
        }

        throw new FormValidationFailedError();
      })
      .then(() => {
        setSubmitting(false);
      });
  }, [values, validateForm, submitFormFormik, setSubmitting]);

  const handleDeleteButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    successProps?: {
      description: string;
      title: string;
      // eslint-disable-next-line
      onResponseReceivedCallback?: (response: any) => ResponseHandlerProps;
      onSuccessCallback?: () => void;
    },
    // eslint-disable-next-line
    onSuccessCallback?: () => void,
    // eslint-disable-next-line
    onResponseReceivedCallback?: (response: any) => ResponseHandlerProps
  ) => {
    return handleServiceCall({
      serviceCall: serviceCall,
      failureProps: { title: `Failed to delete ${entityName}` },
      successProps: successProps
        ? successProps
        : {
            description: '',
            title: `${entityName} deleted`,
            onResponseReceivedCallback: onResponseReceivedCallback,
            onSuccessCallback: () =>
              onSuccessCallback ? onSuccessCallback() : navigate(-1)
          },
      confirmProps: {
        title: `Delete ${entityName}`,
        description: `Are you sure you want to delete this ${entityName}?`,
        confirmButtonLabel: 'Delete',
        cancelButtonLabel: 'Cancel'
      }
    });
  };

  /**
   * @param isResponseHandlingDisabled optional parameter, when set
   * to {@link true}, the response handling like toasts and default
   * navigation to the previous screen are skipped. Defaults to
   * {@link false}.
   * */
  const handleSaveExistingButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    onSuccessCallback?: () => void,
    getSuccessMessageTitle?: (entityName: string) => string,
    getFailureMessageTitle?: (entityName: string) => string,
    isResponseHandlingDisabled?: boolean
  ) => {
    if (isResponseHandlingDisabled) {
      handleServiceCall({ serviceCall: serviceCall });
      return;
    }

    handleServiceCall({
      serviceCall: serviceCall,
      failureProps: {
        title: `Failed to update ${entityName}`
      },
      successProps: {
        description: `The ${entityName} was updated with the changes you specified`,
        title: `${entityName} updated`,
        onSuccessCallback: () =>
          onSuccessCallback ? onSuccessCallback() : navigate(-1)
      }
    });
  };

  /**
   * @param isResponseHandlingDisabled optional parameter, when set
   * to {@link true}, the response handling like toasts and default
   * navigation to the previous screen are skipped. Defaults to
   * {@link false}.
   * */
  const handleSaveNewButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    getSuccessMessageTitle?: (entityName: string) => string,
    getFailureMessageTitle?: (entityName: string) => string,
    onSuccessCallback?: () => void,
    isResponseHandlingDisabled?: boolean
  ) => {
    if (isResponseHandlingDisabled) {
      handleServiceCall({ serviceCall: serviceCall });
      return;
    }

    handleServiceCall({
      serviceCall: serviceCall,
      failureProps: {
        title: getFailureMessageTitle
          ? getFailureMessageTitle(entityName)
          : `Failed to create ${entityName}`
      },
      successProps: {
        description: `The ${entityName} was created with the parameters you specified`,
        title: getSuccessMessageTitle
          ? getSuccessMessageTitle(entityName)
          : `${entityName} created`,
        onSuccessCallback: () =>
          onSuccessCallback ? onSuccessCallback() : navigate(-1)
      }
    });
  };

  const handleDownloadButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string
  ) => {
    buttonDisabledDebounce(setIsDownloadButtonDebouncing);
    handleServiceCall({
      serviceCall: serviceCall,
      successProps: {
        title: `Starting download of ${entityName}...`,
        description: `Starting download of ${entityName}...`
      },
      failureProps: {
        title: `Failed to download ${entityName}`
      }
    });
  };

  const handleBackButtonClick = () => navigate(-1);

  const {
    type,
    label,
    disabled,
    disabledTooltip,
    endIcon,
    startIcon,
    variant,
    color
  } = buttonConfig;

  function withTooltip(
    element: JSX.Element,
    tooltipHidden?: boolean,
    tooltip?: string
  ): JSX.Element {
    if (tooltip) {
      return (
        <Tooltip
          title={tooltipHidden ? tooltip ?? '' : ''}
          trigger={TooltipTrigger.HOVER}
        >
          <Box>{element}</Box>
        </Tooltip>
      );
    } else {
      return element;
    }
  }

  switch (type) {
    case FormButtonType.DELETE_EXISTING_ENTITY:
      return (
        <Button
          disabled={disabled}
          onClick={() =>
            handleDeleteButtonClick(
              buttonConfig.serviceCall || submitForm,
              getTitleCase(buttonConfig.entityName),
              undefined,
              buttonConfig.onSuccessCallback,
              buttonConfig.onResponseReceivedCallback
            )
          }
          endIcon={endIcon || <CloseIcon />}
          color={color}
          variant={variant || 'delete'}
        >
          {label || 'Delete'}
        </Button>
      );

    case FormButtonType.NAVIGATE_BACK:
      return (
        <Button
          disabled={disabled}
          onClick={buttonConfig.onClick || handleBackButtonClick}
          endIcon={endIcon}
          variant={variant || 'back'}
          type="reset"
        >
          {label || 'Back'}
        </Button>
      );

    case FormButtonType.SAVE_CHANGES_TO_EXISTING_ENTITY:
      return withTooltip(
        <Button
          disabled={disabled}
          onClick={() => {
            buttonConfig.onClick && buttonConfig.onClick();
            handleSaveExistingButtonClick(
              submitForm,
              getTitleCase(buttonConfig.entityName),
              buttonConfig.onSuccessCallback,
              buttonConfig.getSuccessMessageTitle,
              buttonConfig.getFailureMessageTitle,
              !!buttonConfig.isResponseHandlingDisabled
            );
          }}
          endIcon={endIcon}
          startIcon={startIcon}
          color="primary"
          variant={variant || 'contained'}
        >
          {label || 'Save'}
        </Button>,
        disabled,
        disabledTooltip
      );

    case FormButtonType.SAVE_NEW_ENTITY:
      return (
        <Button
          disabled={disabled}
          onClick={() => {
            buttonConfig.onClick && buttonConfig.onClick();
            handleSaveNewButtonClick(
              submitForm,
              getTitleCase(buttonConfig.entityName),
              buttonConfig.getSuccessMessageTitle,
              buttonConfig.getFailureMessageTitle,
              buttonConfig.onSuccessCallback,
              !!buttonConfig.isResponseHandlingDisabled
            );
          }}
          endIcon={endIcon}
          color="primary"
          variant={variant || 'contained'}
        >
          {label || 'Create'}
        </Button>
      );

    case FormButtonType.RESET:
      return (
        <Button
          type="reset"
          disabled={disabled}
          endIcon={endIcon}
          variant={variant || 'back'}
        >
          {label || 'Reset'}
        </Button>
      );

    case FormButtonType.DOWNLOAD:
      return (
        <Button
          disabled={disabled || isDownloadButtonDebouncing}
          onClick={() =>
            handleDownloadButtonClick(
              submitForm,
              getTitleCase(buttonConfig.entityName)
            )
          }
          endIcon={endIcon}
          color="primary"
          variant={variant || 'outlined'}
        >
          {label || 'Download'}
        </Button>
      );

    case FormButtonType.CANCEL:
      return (
        <Button
          disabled={disabled}
          onClick={buttonConfig.onClick}
          endIcon={endIcon || <BlockIcon />}
          variant={variant || 'warning'}
        >
          {label || 'Cancel'}
        </Button>
      );

    case FormButtonType.BASE:
      return withTooltip(
        <Button
          disabled={disabled}
          onClick={buttonConfig.onClick}
          endIcon={endIcon}
          color="primary"
          variant={variant}
        >
          {label}
        </Button>,
        disabled,
        disabledTooltip
      );
  }
};
